import {
  ApolloProvider as BaseApolloProvider,
  type NormalizedCacheObject,
} from '@apollo/client';
import React, { type ReactNode, useMemo } from 'react';

import { createApolloClient } from '../../lib/apollo/client';

interface ApolloProviderProps {
  children: ReactNode;
  initialState?: NormalizedCacheObject;
  headers?: Record<string, string>;
}

export function ApolloProvider({
  children,
  initialState,
  headers,
}: ApolloProviderProps) {
  const client = useMemo(
    () => createApolloClient({ initialState, headers }),
    [ initialState, headers ],
  );

  return <BaseApolloProvider client={ client }>{ children }</BaseApolloProvider>;
}
