import {
  ApolloClient,
  InMemoryCache,
  type NormalizedCacheObject,
} from '@apollo/client';

interface CreateApolloClientConfig {
  initialState?: NormalizedCacheObject;
  headers?: Record<string, string>;
}

function createApolloClient({ initialState }: CreateApolloClientConfig = {}) {
  return new ApolloClient({
    uri: 'https://api.dexwallet.com/subgraphs/name/egoldchain/defipower',
    cache: new InMemoryCache().restore(initialState || {}),
  });
}

export { createApolloClient };
